import { Injectable } from '@angular/core';
import {
  BiographiesDetails,
  BiographySearchItem,
} from '../types/bios-profile.type';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppStateService } from './app-state.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from '@utils/logger';
// Logger
const logger = Logger.getLogger('BiosProfileService');

@Injectable({
  providedIn: 'root',
})
export class BiosProfileService {
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  /**
   * Get Profile data from Elastic
   * @param displayName from BR profile Data
   * @returns Observable<BiographiesDetails> from Elastic
   */
  public getProfileFromBiographies(
    employeeId: string
  ): Observable<BiographiesDetails> {
    const url = this.appStateService.getBiographiesUrl();
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body: URLSearchParams = new URLSearchParams();
    body.set('employeeId', employeeId);
    body.set('env', this.appStateService.getEnvironment());

    return this.http
      .post<BiographySearchItem>(url, body, { headers })
      .pipe(
        map((biosProfileData) => {
          return biosProfileData?.results?.response?.hits?.hits[0]?._source;
        }),
        catchError((error: Error) => {
          logger.error(`Error in fetching '${url}' result: `, error);
          return of(null);
        })
      );
  }
}
